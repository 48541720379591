.item {
  padding: 16px;
  border-radius: 16px;
  background: var(--icebreaker-general);
  max-width: 200px;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 16px;
}

.text {
  font-size: 12px;
  color: black;
}

.erotic {
  background: var(--icebreaker-erotic);

  .text {
    color: white;
  }
}

.btn {
  padding: 0 16px;
  height: 28px;
  border-radius: 100px;
  background-color: var(--darkBodyBackground);
  color: white;
  font-size: 10px;
  font-weight: 700;
  align-self: flex-end;
}
