.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-width: 520px;
  width: 100%;
  border-radius: var(--new-design--default-border-radius);
  background: #fff;
  padding: var(--modal-inner-padding);
  color: #000;
}

.containerExtended {
  max-width: 669px;
  max-height: 100vh;
  overflow-y: auto;
}

.containerEmptySize {
  max-width: none;
  width: auto;
  max-height: 100%;
  overflow: auto;
}

.containerWebcamExtended {
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  padding: 0;
  background: transparent;
}

.containerSearch {
  width: 400px;
}

.containerSelectorBig {
  $containerSelectorBigMargin: 16px;

  max-width: 1105px;
  width: calc(100% - #{$containerSelectorBigMargin} * 2);
  height: calc(100vh - 112px);
  margin: #{$containerSelectorBigMargin} #{$containerSelectorBigMargin} 0;
  top: unset;
  bottom: 0;
  transform: translateX(calc(-50% - #{$containerSelectorBigMargin}));
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}
