.buttons {
  display: flex;
  gap: 12px;
  align-items: center;
  padding-right: 16px;

  button {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.icebreakerPickerNew {
  order: -1 !important;
}
