.container {
  display: flex;
  flex-direction: column;
}

.outgoing {
  align-items: flex-end;
}

.card {
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 200px;
  background-color: var(--icebreaker-general);
}

.logo {
  width: 32px;
  filter: invert(100%);
}

.text {
  font-size: 14px;
  color: black;
}

.erotic {
  background-color: var(--icebreaker-erotic);

  .logo {
    filter: unset;
  }

  .text {
    color: white;
  }
}

.subtext {
  margin-top: 4px;
  color: var(--placeholderColor);
  font-size: 10px;
}
