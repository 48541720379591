.title {
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-bottom: 8px;
}

.subtitle {
  color: #616466;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  text-align: center;
  font-size: 9px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.images {
  display: flex;
  flex-direction: row;
  height: 156px;
}

.purpurImage {
  width: 148px;
  height: 148px;
}

.purpurImage:hover {
  width: 156px;
  height: 156px;
  cursor: pointer;
}

.eroticCard {
  margin-right: -10px;
  z-index: 3;
}

.generalCard:hover,
.eroticCard:hover {
  z-index: 4;
}

.modal {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 32px;
  transform: translate(-50%);
  left: 10px;
  width: 341px;
  background-color: #fff;
  border-radius: var(--new-design--default-border-radius);
  padding: 22px 25px;
}
