$titleFontSize: 20px;
$subTitleFontSize: 16px;
$subTitleMarginTop: 12px;
$contentOffset: 32px;

.container {
  height: 100%;
}

.placeholderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-align: center;
  font-size: $titleFontSize;
}

.subtitle {
  text-align: center;
  font-weight: 400;
  font-size: $subTitleFontSize;
  line-height: normal;
  margin-top: $subTitleMarginTop;
}

.content {
  display: flex;
  flex-direction: row;
  margin-top: $contentOffset;
  height: calc(
    100% - #{$titleFontSize} - #{$subTitleFontSize} - #{$subTitleMarginTop} - #{$contentOffset}
  );
  gap: 20px;
}

.logo {
  position: absolute;
  left: 64px;
  bottom: 40px;
}
